import React, { memo } from 'react'

import { heights } from 'libs/items'
import { spaces } from 'libs/theme'

import { Dialog } from 'components/elements/Dialog'
import { Grid, Text } from 'components/elements'
import { Button } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

const Terms = memo(({ isOpen, isFull, setIsOpen }) => {
  return (
    <Dialog isOpen={isOpen} isFull={isFull} close={() => setIsOpen(false)}>
      <section className='dimd-popup'>
        <div className='inner'>
          <Grid columns='24px 1fr 24px' padding={`0 ${spaces.default.px}`} height={`${heights.header}px`} justify='center' align='center'>
            <div />
            <Text>회원가입 및 운영약관 동의</Text>
            <Button type='text' onClick={() => setIsOpen(false)}>
              <Icons icon='close' width='24' height='24' />
            </Button>
          </Grid>
          <div className='terms-info'>
            <p>㈜트러스트랩스가 제공하는 관련 제반 서비스의 이용과 관련하여 필요한 사항을 규정합니다. ㈜트러스트랩스가 제공하는 서비스의 회원약관은 다음과 같은 내용을 담고 있습니다. ㈜트러스트랩스의 서비스는 보건의료기본법 제39조, 제40조 및 제44조, 의료법 제59조1항, 감염병예방법 제4조에 따라, 보건복지부 공고 제2020-177호 및 제 2020-889호에 의거하여 '전화상담 처방 및 대리처방 한시적 허용방안' 및 '약사협의를 통한 수령방식 결정'을 근거로 코로나19 확산을 막기 위한 한시적으로 허용되는 합법적 서비스입니다.</p>
            <p>전화처방서비스는 정부 정책의 변경에 따라 예고 없이 중단될 수 있으며, 회사는 이에 대해 아무런 책임을 지지 않습니다.</p>
            <p>회사는 정부가 한시적으로 허용한 통신진료를 보조하는 시스템을 제공할 뿐, 회원과 의료진(의사 및 병원)간 진료 행위 및 회원 본인의 건강에 관한 의사 결정에 대해 아무런 책임을 지지 않습니다.</p>
            <p>(※중요 안내 사항 : 이용자가 겪고 있는 증상이 경미한 증상이 아닌 경우, 반드시 가까운 병원/응급실에 내원하거나 119를 호출하시기 바랍니다.)</p>

            <h2>제 1조(목적)</h2>
            <p>이 이용약관(이하 ’약관’이라 합니다.)은 주식회사 트러스트랩스 (이하 ’회사’라 합니다.)이 이용고객(이하 ’회원’이라 합니다.)간에 회사가 제공하는 “홈페이지(www.sirs.kr)” 및 “어플리케이션 썰즈(영문명 sirs)”(이하에서는 홈페이지 및 어플리케이션을 이하 ’서비스’라 합니다.)의 가입조건 및 이용에 관한 제반 사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.</p>

            <h2>제 2조(정의)</h2>
            <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
            <p>서비스”라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 “회원”이 이용할 수 있는 트러스트랩스과 관련 제반 서비스를 의미합니다.</p>
            <ul>
              <li>① “회원”이라 함은 회원등록 시 설정한 아이디(ID)로 사이트에 자유롭게 접속하여 사이트의 정보를 지속적으로 제공받을 수 있거나 사이트가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</li>
              <li>② “비회원”이라 함은 이용 계약을 체결하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.</li>
              <li>③”의료진”이라 함은 의사 회원가입 양식에 따라 서비스 가입을 완료하고, 회원들을 위한 원격진료를 할 수 있는 권한을 가진 자를 말합니다.</li>
              <li>④ “아이디(ID)”라 함은 회원의 식별, 정보 제공 및 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여 사이트에 등록된 휴대폰 전화번호를 말합니다.</li>
              <li>⑤ “비밀번호”라 함은 회원이 부여 받은 아이디와 일치되는 회원임을 확인하고 비밀 보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.</li>
              <li>⑥ “인증번호”라 함은 “회원”이 부여 받은 “아이디(휴대폰 전화번호)”를 통해 부여 받은 인증번호를 활용해 일치 되는 ”회원“임을 확인하는 번호를 의미합니다.</li>
              <li>⑦ “단말기”’라 함은 서비스에 접속하기 위해 회원이 이용하는 개인용 컴퓨터, PDA, 휴대전화, 태블릿PC 등의 전산장치를 말합니다.</li>
              <li>⑧ “해지”라 함은 회사 또는 회원이 이용계약을 해약하는 것을 말합니다.</li>
              <li>⑨ “게시물“이라 함은 ”회원“이 ”서비스“를 이용함에 있어 ”서비스상”에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</li>
            </ul>

            <h2>제 3조(약관의 게시와 개정)</h2>
            <ul>
              <li>① “회사”는 이 약관의 내용을 “회원”이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.</li>
              <li>② “회사”는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ”정보통신망법“)” 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
              <li>③ “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 서비스 내 전자우편, 전자쪽지, 로그인 시 동의 창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.</li>
              <li>④ ”회사”가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.</li>
              <li>⑤ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</li>
            </ul>

            <h2>제 4조(약관의 해석)</h2>
            <ul>
              <li>① “회사”는 필요한 경우, 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하 “서비스 별 안내 등”라 합니다)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 ”서비스 별 안내 등”이 우선하여 적용됩니다.</li>
            </ul>

            <h2>제 5조(이용계약 체결)</h2>
            <ul>
              <li>① 이용계약은 “회원”이 되고자 하는 자(이하 “가입신청자”)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 “회사”가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</li>
              <li>② “회사”는 “가입신청자”의 신청에 대하여 “서비스” 이용을 승낙함을 원칙으로 합니다. 다만, “회사”는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.</li>
              <li style={{ marginLeft: 10 }}>I. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 “회사”의 회원 재가입 승낙을 얻은 경우에는 예외로 함.</li>
              <li style={{ marginLeft: 10 }}>II. 실명이 아니거나 타인의 명의를 이용한 경우</li>
              <li style={{ marginLeft: 10 }}>III. 허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지 않은 경우</li>
              <li style={{ marginLeft: 10 }}>IV. 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우</li>
              <li style={{ marginLeft: 10 }}>V. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
              <li>③ 제1항에 따른 신청에 있어 “회사”는 “회원”의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</li>
              <li>④ “회사”는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</li>
              <li>⑤ 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, “회사”는 원칙적으로 이를 가입신청자에게 알리도록 합니다.</li>
              <li>⑥ 이용계약의 성립 시기는 “회사”가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</li>
              <li>⑦ “회사”는 “회원”에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</li>
              <li>⑧ “회사”는 “회원”에 대하여 “영화 및 비디오물의 진흥에 관한 법률” 및 “청소년보호법”등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.</li>
            </ul>

            <h2>제 6조(회원정보의 변경)</h2>
            <ul>
              <li>① “회원”은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 아이디 등은 수정이 불가능합니다.</li>
              <li>② “회원”은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 “회사”에 대하여 그 변경사항을 알려야 합니다.</li>
              <li>③ 제2항의 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.</li>
            </ul>

            <h2>제 7조(개인정보보호 의무)</h2>
            <p>“회사”는 “정보통신망법” 등 관계 법령이 정하는 바에 따라 “회원”의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 “회사”의 개인정보 취급방침이 적용됩니다. 다만, “회사”의 공식 사이트 이외의 링크된 사이트에서는 “회사”의 개인정보취급방침이 적용되지 않습니다.</p>

            <h2>제 8조("회원"의 "아이디" 및 "비밀번호"의 관리에 대한 의무)</h2>
            <ul>
              <li>① 회사는 회원에 대하여 약관에 정하는 바에 따라 이용자 ID를 이용자가 입력한 휴대폰 전화번호로 부여합니다.</li>
              <li>② 이용자ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다. “회원”의 “아이디”와 “인증번호”에 관한 관리책임은 “회원”에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</li>
              <li>③ “회사”는 “회원”의 “아이디”가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 “회사” 및 “회사”의 운영자로 오인할 우려가 있는 경우, 해당 “아이디”의 이용을 제한할 수 있습니다.</li>
              <li>③ “회원”은 “아이디” 및 “비밀번호”가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야 합니다.</li>
              <li>④ 제3항의 경우에 해당 “회원”이 “회사”에 그 사실을 통지하지 않거나, 통지한 경우에도 “회사”의 안내에 따르지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.</li>
            </ul>

            <h2>제 9조("회원"에 대한 통지)</h2>
            <ul>
              <li>① “회사”가 “회원”에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지, 등으로 할 수 있습니다.</li>
              <li>② “회사”는 “회원” 전체에 대한 통지의 경우 7일 이상 “회사”의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</li>
            </ul>

            <h2>제 10조("회사"의 의무)</h2>
            <ul>
              <li>① “회사”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여 노력합니다.</li>
              <li>② “회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.</li>
              <li>③ “회사”는 서비스 이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</li>
              <li>④ “회사”는 서비스 이용과 관련하여 “회원” 으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. “회원”이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 “회원”에게 처리과정 및 결과를 전달합니다.</li>
            </ul>

            <h2>제 11조("회원"의 의무)</h2>
            <ul>
              <li>① “회원”은 다음 행위를 하여서는 안 됩니다.</li>
              <li style={{ marginLeft: 10 }}>I. 신청 또는 변경 시 허위내용의 등록</li>
              <li style={{ marginLeft: 10 }}>II. 타인의 정보도용</li>
              <li style={{ marginLeft: 10 }}>III. “회사”가 게시한 정보의 변경</li>
              <li style={{ marginLeft: 10 }}>IV. “회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
              <li style={{ marginLeft: 10 }}>V. “회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
              <li style={{ marginLeft: 10 }}>VI. “회사” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
              <li style={{ marginLeft: 10 }}>VII. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “서비스”에 공개 또는 게시하는 행위</li>
              <li style={{ marginLeft: 10 }}>VIII. 회사의 동의 없이 영리를 목적으로 “서비스”를 사용하는 행위</li>
              <li style={{ marginLeft: 10 }}>IX. 기타 불법적이거나 부당한 행위</li>
              <li>② “회원”은 관계법, 이 약관의 규정, 이용안내 및 “서비스”와 관련하여 공지한 주의사항, “회사”가 통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다. 또한, 회원은 연락처, 전자우편 주소 등 이용계약사항이 변경된 경우에 해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다.</li>
            </ul>

            <h2>제 12조("서비스"의 제공 등)</h2>
            <ul>
              <li>① 회사는 회원에게 아래와 같은 서비스를 제공합니다.</li>
              <li style={{ marginLeft: 10 }}>I. (Web, App) 진료과목별 의사검색 서비스</li>
              <li style={{ marginLeft: 10 }}>II. (Web, App) 원격진료 신청하기 서비스</li>
              <li style={{ marginLeft: 10 }}>III. (Web, App) 원격 화상진료 지원 서비스</li>
              <li style={{ marginLeft: 10 }}>IV. (Web, App) 원격 전화진료 지원 서비스</li>
              <li style={{ marginLeft: 10 }}>V. (Web, App) 전문가 Q&A(의사/약사 Q&A)</li>
              <li style={{ marginLeft: 10 }}>VI. (Web, App) 약국 처방약 배달 교부 및 수령 지원 서비스</li>
              <li style={{ marginLeft: 10 }}>VII. (Web, App) 약국 처방전 전송 지원 서비스</li>
              <li style={{ marginLeft: 10 }}>VIII. (Web, App) 약국 약값 결제 서비스</li>
              <li style={{ marginLeft: 10 }}>IX. (Web, App) 의학/약학 저널 서비스</li>
              <li style={{ marginLeft: 10 }}>X. (Web, App) 트러스트랩스 PB 판매</li>
              <li style={{ marginLeft: 10 }}>XI. (Web, 병원 Admin) 의사의 회원가입 서비스</li>
              <li style={{ marginLeft: 10 }}>XII. (Admin) 약사의 회원가입 서비스</li>
              <li style={{ marginLeft: 10 }}>XII. (Web, 병원 Admin) 의사의 원격진료 서비스</li>
              <li style={{ marginLeft: 10 }}>XIV. (Admin) 약사의 처방전 수령 도움 서비스</li>
              <li style={{ marginLeft: 10 }}>XV. 기타 “회사”가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 “회원”에게 제공하는 일체의 서비스(예) 제휴 친구초대 시 쿠폰제공 서비스 등 기타 기술 서비스 포함</li>
              <li>② 회사는 보건복지부의 비대면 진료 한시적 허용 지침(공고 제 2020-177호, 제 2020-889호)에 의거해 비대면 진료 및 처방약 교부 서비스를 제공합니다. 이 중 '약사'와 '환자' 간의 협의를 통해 처방약의 교부와 수령 방식을 정할 수 있다는 지침에 따라, 자사 플랫폼 가입 회원 및 제휴 회원 약국에 한해 '배달'과 '배송'의 교부-수령 방식을 지원합니다.</li>
              <li>③ 회사는 “서비스”를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</li>
              <li>④ “서비스”는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
              <li>⑤ “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 “서비스”의 제공을 일시적으로 중단할 수 있습니다. 이 경우 “회사”는 제9조 “회원”에 대한 통지에 정한 방법으로 “회원”에게 통지합니다. 다만, “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
              <li>⑥ “회사”는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.</li>
            </ul>

            <h2>제 13조(“서비스”의 변경)</h2>
            <ul>
              <li>① “회사”는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 “서비스”를 변경할 수 있습니다.</li>
              <li>② “서비스”의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.</li>
              <li>③ “회사”는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 “회원”에게 별도의 보상을 하지 않습니다.</li>
            </ul>

            <h2>제 14조(서비스이용료)</h2>
            <p>회사는 회원과 의료진 간의 자유로운 상담서비스 이용에 필요한 각종 서비스를 제공하고, 회사의 내부 정책에 따라 그에 대한 이용료(서비스 이용료) 또는 상담촉진서비스이용료 등을 부과할 수 있습니다.</p>

            <h2>제 15조(비대면 진료 대금의 정산)</h2>
            <p>의료진이 트러스트랩스을 통하여 수행한 원격진료 용역 대금(이하 “비대면 진료 대금”이라 함)에 대한 정산은 원격진료서비스 가격에서 제14조의 서비스 이용료 및 비대면 진료촉진서비스이용료 등을 제외한 금액을 기준으로 산정되며, 의료진은 서비스 이용료 등을 고려하여 비대면진료 서비스 가격을 자유롭게 정할 수 있습니다.</p>
            <p>정산주기는 금월 진료 완료 건에 대한 비대면진료 대금을 익월 10일에 지급하는 것을 원칙으로 하며, 회사와 의료진이 별도로 정산주기를 정한 경우에는 이 기준에 따릅니다. 단, 회사는 비대면 진료 대금을 지급하기 이전에 회원이 취소 또는 환불의 의사를 표시한 경우 비대면 진료 대금의 지급을 보류할 수 있습니다.</p>
            <p>회사는 의료진의 귀책사유로 인해 발생한 비용을 비대면 진료 대금에서 공제할 수 있습니다.</p>
            <p>회사는 아래의 사유가 발생하는 경우 비대면 진료 대금의 정산을 유보할 수 있습니다.</p>
            <ul>
              <li>① 회원이 신용카드로 결제한 경우, [여신전문금융업법]상의 규정에 따라 회사는 신용카드 부정사용을 통한 허위거래여부를 판단하기 위해 최고 60일까지 비대면진료 대금에 대한 송금을 보류할 수 있습니다. 이 경우, 회사는 비대면진료 사실 확인을 위한 증빙을 의료진에 요구할 수 있으며, 회사는 사실 여부 확인 후 비대면진료 대금을 지급할 수 있습니다.</li>
              <li>② 법원 등 제3자가 자격을 갖추어 비대면 진료 대금의 지급보류를 요청한 경우 회사는 보류요청이 해제될 때까지 관련 비대면 진료 대금의 송금을 보류하거나 정당한 채권자에게 지급할 수 있습니다</li>
              <li>③ 회사는 의료진이 상담서비스 제공 부적격자로 적발되거나, 회원 클레임의 다수 발생으로 인한 환불 등의 요청이 염려되는 경우 일정 기간 정산을 보류할 수 있습니다.</li>
              <li>④ 의료진과 회원 간에 동일한 유형의 클레임이 지속적으로 발생하는 경우, 회원 클레임에 대비하여 일정기간 비대면 진료 대금의 정산을 유보할 수 있습니다.</li>
            </ul>
            <p>본 조에 정한 외에도 법률의 규정에 의하거나 합리적인 사유가 있는 경우에는 회사는 의료진에게 통지하고 비대면 진료 대금의 전부 또는 일부에 대한 정산을 일정 기간 유보할 수 있습니다.</p>

            <ul>
              <li>① 본 사이트의 발생한 모든 민,형법상 책임은 회원 본인에게 1차적으로 있습니다.</li>
              <li>② 본 사이트로부터 회원이 받은 손해가 천재지변 등 불가항력적이거나 회원의 고의 또는 과실로 인하여 발생한 때에는 손해배상을 하지 하지 않습니다.</li>
            </ul>

            <h2>제 16조(정보의 제공 및 광고의 계약)</h2>
            <ul>
              <li>① “회사”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 “회원”에게 제공할 수 있습니다. 다만, “회원”은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.</li>
              <li>② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 “회원”의 사전 동의를 받아서 전송합니다. 다만, “회원”의 거래관련 정보, 상담하기, 혜택받기 서비스로 인한 이메일 발송 및 문자, 통화와 관련된 내용은 제외됩니다.</li>
              <li>③ “회사”는 “서비스”의 운영과 관련하여 서비스 화면, 홈페이지, 어플리케이션, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 “회원”은 수신거절을 “회사”에게 할 수 있습니다.</li>
              <li>④ “이용자(회원, 비회원 포함)”는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.</li>
              <li>⑤ ”회사(주식회사 트러스트랩스)”는 회원가입 하신 “이용자(의사 및 약사)”분들을 위해 온, 오프라인으로 무료 광고를 대행할 수 있습니다.</li>
            </ul>

            <h2>제 17조("게시물"의 저작권)</h2>
            <ul>
              <li>① “회원”이 “서비스” 내에 게시한 “게시물”의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</li>
              <li>② “회원”이 “서비스” 내에 게시하는 “게시물”은 검색결과 내지 “서비스” 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, “회원”은 언제든지 고객센터 또는 “서비스” 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.</li>
              <li>③ “회사”는 제2항 이외의 방법으로 “회원”의 “게시물”을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 “회원”의 동의를 얻어야 합니다.</li>
            </ul>

            <h2>제 18조("게시물"의 관리)</h2>
            <ul>
              <li>① “회원”의 “게시물”이 “정보통신망법” 및 “저작권법”등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 “게시물”의 게시중단 및 삭제 등을 요청할 수 있으며, “회사”는 관련법에 따라 조치를 취하여야 합니다.</li>
              <li>② “회사”는 전항에 따른 권리자의 요청이 없는 경우라도 아래 규정에서와 같이 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 “게시물”에 대해 임시조치 등을 취할 수 있습니다.</li>
              <li style={{ marginLeft: 10 }}>– 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우</li>
              <li style={{ marginLeft: 10 }}>– 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크 시키는 경우</li>
              <li style={{ marginLeft: 10 }}>– 불법복제 또는 해킹을 조장하는 내용인 경우</li>
              <li style={{ marginLeft: 10 }}>– 영리를 목적으로 하는 광고일 경우</li>
              <li style={{ marginLeft: 10 }}>– 범죄와 결부된다고 객관적으로 인정되는 내용일 경우</li>
              <li style={{ marginLeft: 10 }}>– 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
              <li style={{ marginLeft: 10 }}>– 사적인 정치적 판단이나 종교적 견해의 내용으로 회사가 서비스 성격에 부합하지 않는다고 판단하는 경우</li>
              <li style={{ marginLeft: 10 }}>– 타인의 건강을 위해 하는 검증되지 않은 허위정보로 판단될 경우</li>
              <li style={{ marginLeft: 10 }}>– 교육인 및 국내 교육기관 간의 비교 광고 성격으로 판단될 경우</li>
              <li style={{ marginLeft: 10 }}>– 의료인 및 국내 의료기관 간의 비교 광고 성격으로 판단될 경우</li>
              <li style={{ marginLeft: 10 }}>– 허가 받지 않은 의료행위 또는 의료행위를 위한 선전으로 게시물을 게재할 경우</li>
              <li style={{ marginLeft: 10 }}>– 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우</li>
              <li style={{ marginLeft: 10 }}>– 기타 관계법령에 위배된다고 판단되는 경우</li>
              <li>③ 본 조에 따른 세부절차는 “정보통신망법” 및 “저작권법”이 규정한 범위 내에서 “회사”가 정한 “게시중단요청서비스”에 따릅니다.</li>
            </ul>

            <h2>제 19조(권리의 구속)</h2>
            <ul>
              <li>① “서비스”에 대한 저작권 및 지적재산권은 “회사”에 귀속됩니다. 단, “회원”의 “게시물” 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.</li>
              <li>② “회사”는 서비스와 관련하여 “회원”에게 “회사”가 정한 이용조건에 따라 계정, “아이디”, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, “회원”은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.</li>
            </ul>

            <h2>제 20조(계약해제, 해지 등)</h2>
            <ul>
              <li>① “회원”은 언제든지 서비스초기화면의 고객센터 또는 내 정보 관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, “회사”는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.</li>
              <li>② “회원”이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 “회사”가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 “회원”의 모든 데이터는 소멸됩니다.</li>
            </ul>

            <h2>제 21조(손해배상)</h2>
            <ul>
              <li>① 회사와 이용자는 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우에는 이를 배상하여야 합니다.</li>
              <li>② 단, 회사는 무료로 제공하는 서비스의 이용과 관련하여 개인정보보호정책에서 정하는 내용에 위반하지 않는 한 어떠한 손해도 책임을 지지 않습니다.</li>
            </ul>

            <h2>제 22조(이용제한 등)</h2>
            <ul>
              <li>① “회사”는 “회원”이 약관의 의무를 위반하거나 “서비스”의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 “서비스” 이용을 단계적으로 제한할 수 있습니다.</li>
              <li>② “회사”는 전항에도 불구하고, “주민등록법”을 위반한 명의도용 및 결제도용, “저작권법” 및 “컴퓨터프로그램보호법”을 위반한 불법프로그램의 제공 및 운영방해, “정보통신망법”을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 “서비스” 이용을 통해 획득한 혜택 등도 모두 소멸되며, “회사”는 이에 대해 별도로 보상하지 않습니다.</li>
              <li>③ “회사”는 “회원”이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.</li>
              <li>④ “회사”는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.</li>
              <li>⑤ 본 조에 따라 “서비스” 이용을 제한하거나 계약을 해지하는 경우에는 “회사”는 제9조 “회원”에 대한 통지에 따라 통지합니다.</li>
              <li>⑥ “회원”은 본 조에 따른 이용제한 등에 대해 “회사”가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 “회사”가 인정하는 경우 “회사”는 즉시 “서비스”의 이용을 재개합니다.</li>
            </ul>

            <h2>제 23조(책임제한)</h2>
            <ul>
              <li>① “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이 면제됩니다.</li>
              <li>② “회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여는 책임을 지지 않습니다.</li>
              <li>③ “회사”는 “회원”이 “서비스”와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</li>
              <li>④ “회사”는 “회원” 간 또는 “회원”과 제3자 상호간에 “서비스”를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.</li>
              <li>⑤ “회사”는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</li>
            </ul>

            <h2>제 24조(준거법 및 재판관할)</h2>
            <ul>
              <li>① “회사”와 “회원” 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
              <li>② “회사”와 “회원”간 발생한 분쟁에 관한 소송은 제소 당시의 “회원”의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 “회원”의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.</li>
            </ul>

            <h2>부칙</h2>
            <ul>
              <li>1.이 약관은 2021년 9월 1일부터 적용됩니다.</li>
              <li>2.회사는 약관을 변경하는 경우에 회원이 그 변경 여부, 변경된 사항의 시행시기와 변경된 내용을 언제든지 쉽게 알 수 있도록 지속적으로 ‘서비스’ 를 통하여 공개합니다. 이 경우에 변경된 내용은 변경 전과 후를 비교하여 공개합니다.</li>
            </ul>
          </div>
        </div>
      </section>
    </Dialog>
  )
}, (p, n) => {
  if (p.isOpen !== n.isOpen) return false
  if (p.isFull !== n.isFull) return false
  return true
})

export default Terms
